import { motion } from 'framer-motion';
import Link from 'next/link';
import React from 'react';
import { YellowButtonProps } from '../../data/types';
import styles from './YellowButton.module.scss';

const YellowButton: React.FC<YellowButtonProps> = ({ href, children, onClick, enabled = true }) => {
  const buttonOpacity = enabled ? 1 : 0.5;
  if (!href && onClick) {
    return (
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: buttonOpacity }}
        transition={{ delay: 0.2 }}
        className={styles.YellowButton}
        whileTap={{ scale: 0.9 }}
        onClick={enabled ? onClick : null}
        style={{ cursor: enabled ? 'pointer' : 'not-allowed' }}
      >
        {children}
      </motion.div>
    );
  }
  return (
    <Link href={href}>
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: buttonOpacity }}
        transition={{ delay: 0.2 }}
        className={styles.YellowButton}
        whileTap={{ scale: 0.9 }}
        onClick={enabled ? onClick : null}
        style={{ cursor: enabled ? 'pointer' : 'not-allowed' }}
      >
        {children}
      </motion.div>
    </Link>
  );
};

export default YellowButton;
