import MessageCard from '../MessageCard/MessageCard';

const OnboardMessage: React.FC<{ takeOver?: boolean }> = ({ takeOver }) => (
  <MessageCard title="How it works" ctaHref="/genres" ctaText="Got it!" takeOver={takeOver}>
    <p>
      Each of the cards on the next screen are &quot;ways of being&quot; in the world. Tap the
      following cards in order from most like you to least like you.
    </p>
  </MessageCard>
);

export default OnboardMessage;
