import { motion } from 'framer-motion';
import React, { ReactElement } from 'react';
import { MessageCardProps } from '../../data/types';
import YellowButton from '../YellowButton/YellowButton';
import styles from './MessageCard.module.scss';

const MessageCard: React.FC<MessageCardProps> = ({
  title,
  subtitle,
  ctaText,
  ctaHref,
  takeOver = false,
  children,
}) => {
  const optionalTakeOver = (contents: ReactElement) => {
    if (takeOver) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0 }}
          className={styles.MessageTakeOver}
        >
          {contents}
        </motion.div>
      );
    }
    return contents;
  };
  return optionalTakeOver(
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.2 }}
      className={styles.MessageCard}
      style={takeOver ? { padding: '60px', maxWidth: '510px' } : {}}
    >
      {title ? <h3>{title}</h3> : null}
      {subtitle ? <h4>{subtitle}</h4> : null}
      {children}
      {ctaHref ? (
        <div className={styles.buttonCenter}>
          <YellowButton href={ctaHref}>{ctaText || 'Let’s Go'}</YellowButton>
        </div>
      ) : null}
    </motion.div>
  );
};

export default MessageCard;
