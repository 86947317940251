import { NextPage } from 'next';
import React, { useState } from 'react';
import YellowButton from '../components/YellowButton/YellowButton';
import styles from './index.module.scss';
import unsw from '../assets/home-unsw.png';
import insideOut from '../assets/home-inside-out.svg';
import { motion } from 'framer-motion';
import Image from 'next/image';
import OnboardMessage from '../components/OnboardMessage/OnboardMessage';
import useMedia from 'use-media';
import { useRouter } from 'next/router';

const HomePage: NextPage = () => {
  const isDesktop = useMedia({ minWidth: '768px' });
  const [showOnboard, setShowOnboard] = useState(false);
  const router = useRouter();

  const letsGoHandler = () => {
    if (isDesktop) {
      setShowOnboard(true);
    } else {
      router.push('/onboarding');
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={styles.Home}
      layoutId="mainBackground"
    >
      {showOnboard ? <OnboardMessage takeOver /> : null}
      <div className={styles.centeredBlock}>
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Image className={styles.unswLogo} src={unsw} alt="UNSW" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <Image className={styles.insideOutLogo} src={insideOut} alt="Inside Out" />
        </motion.div>
        <motion.h3 initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }}>
          How do your values shape your future?
        </motion.h3>
        <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 0.4 }}>
          With Inside Out, discover how your values match with different degrees at UNSW and map
          your future career!
        </motion.p>
        <YellowButton onClick={letsGoHandler}>Let’s Go</YellowButton>
      </div>
    </motion.div>
  );
};

export default HomePage;
